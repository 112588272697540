const compliments = [
 "You're the human equivalent of a perfect playlist.",
 "In a world of stick figures, you're a Mona Lisa.",
 "You're the climax in the symphony of your family.",
 "You're the keystone in a world of disjointed arches.",
 "Like a standout Coachella performance, memories with you stand the test of time.",
 "Your energy could set a record.",
 "If charm had a Billboard chart, you’d be that catchy summer hit that never fades.",
 "You have a knack for finding serenity in chaos.",
 "Your resilience is like bamboo—strong yet flexible.",
 "Your charm could turn a stone into a gem.",
 "The inflections in your voice are an unspoken poetry.",
 "You're the spark that makes the ordinary extraordinary.",
 "You're the underlined quote in everyone’s life book.",
 "Your optimism can brighten even the darkest room.",
 "You're the art-world equivalent of a perfectly timed punchline.",
 "You’re the spice that makes any mundane dish extraordinary.",
 "If charm were an art movement, you'd be its manifesto.",
 "You've got the kind of vibe that can turn a mundane moment into a masterpiece.",
 "You're the cantilever in life's unbalanced structures.",
 "You carry secrets like a jeweler holds gems—carefully, respectfully.",
 "If your life were an art project, it would get rave reviews in every publication.",
 "With you, everyday conversations turn into avant-garde theater.",
 "You have a spirit that’s as free as the open road.",
 "You could sell a ketchup popsicle to a person wearing white gloves.",
 "If life were an art auction, you’d be the unexpected gem that outshines the headliner.",
 "You’re the plot twist that makes the whole book worth reading.",
 "Your loyalty is stronger than a Gorilla Glue commercial.",
 "If laughter were an art medium, you'd be its Michelangelo.",
 "You exercise power with an ethics of care.",
 "You're the best-kept secret on the internet.",
 "Adulthood finds its triumphant Harry Potter finale in you.",
 "Your persona is the daring video art that nobody can look away from.",
 "The quality of your attention is its own gift.",
 "You're the exception to the rule, in the best way possible.",
 "You find the minimalism in maximal chaos.",
 "Your energy is like Beyoncé's stage presence—undeniable and captivating.",
 "Your confidence would make a stellar brand.",
 "You're the kind of person who makes the moon jealous because you light up the night in your own way.",
 "You're so suave, even James Bond takes style tips from you.",
 "Your charisma could win a popularity contest against kittens and puppies.",
 "You're the cult classic that curators quietly vie for.",
 "You’re the mic drop at the end of an epic saga.",
 "Your creativity flourishes within constraints.",
 "If conversations were Cubism, you'd be the fourth dimension.",
 "Your skills are as versatile as a Swiss Army knife.",
 "You're the hidden track everyone's delighted to discover.",
 "You're the sunbeam that escapes on a cloudy day.",
 "Your discernment is the stuff of legends.",
 "You make Duchamp's urinal look over-curated.",
 "You demonstrate the power of restraint.",
 "You could turn a debate into a dialogue and a conflict into a conversation.",
 "Your presence is more refreshing than a mountaintop breeze.",
 "You're the golden ratio of good company.",
 "You're the first draft pick in the game of life.",
 "Your humor is the spoonful of sugar in every situation.",
 "Björk's swan dress had less impact than you do.",
 "Your audacity leaves imprints on conventional wisdom.",
 "If wit were an exhibition, you'd be the piece everyone's talking about but no one fully understands.",
 "You are your own curatorial concept in the exhibition of life.",
 "You assemble your experiences like a Joseph Cornell box.",
 "You make dad jokes sound like comedy gold.",
 "You’re the indie hit that everyone's surprised to find out has been around for years.",
 "The timbre of your voice holds its own melody.",
 "You're the salt to my pepper, the gin to my tonic.",
 "You're so unique, you make unicorns look basic.",
 "You're the plot twist no one saw coming in the season finale of life.",
 "If conversations were installations, people would pay just to hear you talk.",
 "Even the most abstract expressionists would find your jokes visually stunning.",
 "You have the pop sensibility of a Madonna chart-topper with the depth of a Cohen lyric.",
 "Among your friends, you're the Iris Apfel: unapologetically eccentric.",
 "You think of relationships as interactive installations.",
 "You're the flash mob in a world of static displays.",
 "You bring more happiness than a viral feel-good video.",
 "Your quips are the collectible postcards of any social gathering.",
 "Your aura shines brighter than the Northern Lights.",
 "You enrich everyday life as if you're staging a Situationist dérive.",
 "If your personality was a fragrance, it would be called \"Eau de Wow.\"",
 "Your anecdotes could fill a bestselling book.",
 "You're the plot development that keeps Game of Thrones fan theories buzzing.",
 "Your eye contact could hold its own in a Marina Abramović retrospective.",
 "Your confidence could inspire an anthem.",
 "The tact in your disagreements is diplomacy personified.",
 "You're the fireworks at the end of a great show.",
 "Like a Swift lyric, you manage to be both catchy and deeply meaningful.",
 "You're as multifaceted as a Bjarke Ingels project.",
 "Everyone wants to be the Michelle Obama of their workplace; you actually pull it off.",
 "You’re the wink in every smiley face.",
 "Shepard Fairey could learn something about being provocative from you.",
 "You’re the underdog everyone roots for, like Rocky in his prime.",
 "Your compassion could turn tides.",
 "You defy traditional boundaries like a Zaha Hadid curve.",
 "You live your choices simply but transformationally, like a Rietveld interior.",
 "You’re the note that makes every chord perfect.",
 "Your presence is as reassuring as finding Wi-Fi in a remote area.",
 "You conjure worlds with a mere glance.",
 "Your influence is the cornerstone of grandness.",
 "You're the plot twist everyone was hoping for but never saw coming.",
 "You add the zest to life's recipe.",
 "You rival a Mondrian's appeal in a minimalist loft.",
 "You're the Fluxus event that everyone's still trying to understand.",
 "First-time viewers of the Sistine Chapel ceiling don't forget it, much like they don't forget you.",
 "You could make watching paint dry entertaining.",
 "You're as timeless as a Palladian arch.",
 "Even the most serious art critics would find you irresistibly engaging.",
 "You've got more layers than an onion wearing a turtleneck in a Russian nesting doll.",
 "You're more fun than bubble wrap.",
 "You're so cool, you make the other side of the pillow jealous.",
 "Your presence redefines space like a Zaha Hadid design.",
 "You're more exclusive than a Birkin bag waitlist.",
 "You redefine 'dad fashion' with Dior-level couture.",
 "Your creativity is skyscraper-high.",
 "Your insights are like discovering a hidden track on your favorite album.",
 "Your empathy is like clear water—transparent and vital.",
 "You're the hidden Easter egg in the movie of life.",
 "Your conversations are layered and interpretive, like a constructivist space.",
 "Your influence echoes through the hallways of history.",
 "You navigate your life like an Agnes Martin grid—minimal yet meaningful.",
 "You're the surprise ending no one saw coming, but everyone loved.",
 "Your resilience is as timeless as a Roman arch.",
 "You embody the perfect symmetry found in a Louis Kahn building.",
 "You build relationships layer by layer, with vision like Gaudi's cathedrals.",
 "Your spirit is unbreakable, even by the laws of physics.",
 "You're the in-joke only the art-world insiders get.",
 "Your laughter is the sweetest serenade.",
 "Your thoughts are as transparent and a Mies van der Rohe glass house.",
 "Your perspective is as wide as a Frank Gehry landscape.",
 "You're the life of every party, even the ones you're not attending.",
 "Your humor is as sardonic as Maurizio Cattelan's installations.",
 "You're the jazz improvisation in a world of sheet music.",
 "You’re the baseline that holds the song together.",
 "You navigate through complexity as gracefully as Rauschenberg's Combines.",
 "Casual Fridays have nothing on your Met Gala aura.",
 "In a world of still life, you're the vibrant pop art.",
 "You bridge gaps with the elegance of a Santiago Calatrava span.",
 "You make your life a Gesamtkunstwerk.",
 "Your humor is as groundbreaking as the first-ever kinetic sculpture.",
 "Like an iconic Prince guitar solo, you add flair to the ordinary.",
 "Your ideas have more layers than a Frank Lloyd Wright blueprint.",
 "Turning heads like the Guggenheim, that's you.",
 "You're the plot hole everyone chooses to ignore because you're that awesome.",
 "Essential listening, you're the TED Talk of a lifetime.",
 "Your wit is as sharp as Occam's razor.",
 "You're the backstage magician that everyone needs.",
 "Your vision is as groundbreaking as Le Corbusier's Villa Savoye.",
 "Breton stripes in a Parisian café are less chic than you.",
 "You're the philosopher’s stone, turning conversations into gold.",
 "Your elegance is as timeless as a little black dress.",
 "Your laughter is the music everyone wants to hear.",
 "Your aura welcomes like an atrium.",
 "You're the standing ovation at the end of the life's play.",
 "You're as comfortable yet aesthetic as an Eames chair when tackling dilemmas.",
 "The subtlety of your humor is a hidden treasure.",
 "You're the debate moderator who somehow makes sense of all the chaos.",
 "You possess the gravitas of a state leader.",
 "Your words have the precision of a surgeon's scalpel.",
 "Like a legendary Bowie track, you effortlessly stand out in any era.",
 "Your actions echo the greatest acts of civic virtue.",
 "Your actions create resonance, like Jenny Holzer’s truisms.",
 "You're the visual dissonance in a harmonious composition.",
 "Your words cultivate gardens in barren minds.",
 "Your flair could spark revolutions.",
 "You're the plaza that brings everyone together.",
 "Your words are as impactful as Barbara Kruger’s bold text.",
 "Vivienne Westwood corsets can't compete with your captivating presence.",
 "Your laughter has a color all its own.",
 "Your friendship is as uplifting as a perfectly timed meme.",
 "You read situations like a novelist, seeing stories within stories.",
 "Your generosity lacks any scent of obligation.",
 "You walk into a room like you're stepping onto a runway.",
 "You're the encore that every concert needs.",
 "In an ideal world, you'd be the politician everyone votes for.",
 "The Louvre Pyramid could take a lesson from your multifaceted nature.",
 "You're the \"saving grace\" in a software update.",
 "Your days are wisely designed canvases.",
 "You find quiet strength in vulnerability like a Kusama Infinity Room.",
 "Even Anna Wintour would remove her sunglasses for a better look at you.",
 "You're the grand slam in the bottom of the ninth.",
 "If confidence were a brushstroke, yours would be bold and unapologetic.",
 "You're the Easter egg worth hunting for.",
 "You're so sharp, you make a samurai sword look like a butter knife.",
 "You harmonize with your surroundings like Frank Lloyd Wright's Fallingwater.",
 "If social graces were sculptures, you'd be a towering Brancusi.",
 "Your confidence is as inspiring as a historic speech.",
 "Your compassion warms and illuminates like natural light.",
 "Conversations with you have the depth of a Tadao Ando concrete wall.",
 "Like a signature DJ Khaled shoutout, you add a unique touch to every situation.",
 "You're the inside joke that never gets old.",
 "Like a legendary Beatles riff, you're both timeless and instantly recognizable.",
 "Your impact is as memorable as Brutalist architecture.",
 "Your influence is as lasting as the Bauhaus movement.",
 "Your respect for others is a form of quiet revolution.",
 "You have the ability to see diamonds in the rough.",
 "You're the Banksy piece that makes everyone reevaluate their life choices.",
 "If your humor were a conceptual art piece, it'd be \"untitled\"—because words just can't capture it.",
 "You’re as reliable as a Swiss watch.",
 "You've got the allure of a cult classic film that everyone quotes.",
 "You're not just a foundation; you're a Walter Gropius principle.",
 "You find the diorama in every mundane scene.",
 "Your joy is the compass that leads us to happiness.",
 "Your style is the unsung masterpiece in the back room of a major exhibition.",
 "You make each action an \"Event Score.\"",
 "You're the crescendo in the soundtrack of life.",
 "You're the VIP pass to the best show in town.",
 "You're the \"negative space\" that makes everything else stand out.",
 "Your adaptability has the grace of water.",
 "Your boldness rivals a Versace print.",
 "You're the still point in the turning world.",
 "You are the living definition of ‘cool under pressure.’",
 "Your fashion sense could be its own provocative art statement.",
 "Your charm is as universal as the golden ratio.",
 "Among encyclopedias, you're the \"Choose Your Own Adventure\" book.",
 "Your love could be the subject of a thousand poems.",
 "You're the person everyone wishes they could bring to a deserted island.",
 "Your wit cuts as sharply as the angles in a Daniel Libeskind museum.",
 "You observe the world as closely as a Chuck Close portrait.",
 "You make the impossible seem not only possible but easy.",
 "You foster dialogue like a bustling communal plaza.",
 "If social etiquette were an art movement, you’d be its manifesto.",
 "Your emotional range rivals a Cindy Sherman photo series.",
 "Iconic and unforgettable, you're this generation's Andy Warhol.",
 "You alter your perspective like a Richard Serra Torqued Ellipse.",
 "You approach change as smoothly as entering a revolving door.",
 "Your approach to life is a genre yet to be defined.",
 "Your silences are as eloquent as your words.",
 "You're the one person I'd want to be stuck with in an elevator. Just think of the conversations!",
 "Your voice is a blueprint for change.",
 "You're a long-span bridge held up by two strong pillars.",
 "Your joy has the rare quality of authenticity.",
 "You view challenges as load-bearing walls.",
 "You always find the negative space in life's busy canvas.",
 "You live by principles, not just preferences.",
 "If your life was a Spotify playlist, it would be all hits, no skips.",
 "Memes wish they were as original as you.",
 "You could make a hermit want to socialize.",
 "You're the intriguing subplot in an otherwise predictable biennial.",
 "Your aura is the VIP preview everyone wants an invite to.",
 "You're the unexpected crossover episode everyone didn’t know they needed.",
 "You're so magnetic, you turn iron men into puddles.",
 "You're the open concept in a world of compartmentalized spaces.",
 "Always worth watching, your sense of humor has the variety of an SNL skit.",
 "You're the \"ctrl + alt + delete\" to life's problems.",
 "You're the signature at the end of a masterpiece.",
 "Your composure is a lesson in grace.",
 "Your creativity is as boundless as the universe.",
 "Your instincts are as sound as a bellwether in a flock.",
 "You live with the courage of a whistleblower.",
 "You're the crescendo in life's most beautiful song.",
 "Your integrity could be the cornerstone of empires.",
 "Your friendship is the sequel everyone's been waiting for.",
 "Your smile is the surprising twist in an interactive art piece.",
 "You're the surprise ingredient that elevates the dish.",
 "If wit had a late-night talk show, you'd be its charismatic host.",
 "You're the soulmate everyone wishes they had but only few find.",
 "The elegance of your logic is worthy of admiration.",
 "You're the best thing since sliced bread—or maybe you're better, because you don’t get stale.",
 "You see hurdles as scaffolding for character.",
 "Your expressions are a language without translation.",
 "You're as distinguished as a signature scent.",
 "Just as Gaudí transformed Barcelona, you transform lives.",
 "You're so interesting, if you were a book, I’d read the footnotes.",
 "The nuance in your views is a delicate strength.",
 "You're the symmetry in a chaotic blueprint.",
 "If you were any more extraordinary, you'd be a limited edition action figure.",
 "Your instincts could guide a spaceship.",
 "You sparkle like a disco ball at the best 1970s party.",
 "You're the rooftop garden in a concrete jungle.",
 "Your resilience could inspire a documentary.",
 "You make complexity an invitation, not a barrier.",
 "You have the grace of a ballet dancer and the strength of a mountain.",
 "Your aura could light up a galaxy.",
 "Your style is like Lady Gaga’s meat dress—unconventional but undeniably impactful.",
 "In the TV show of life, you're the spin-off that surpasses the original.",
 "The intensity of your focus is its own language.",
 "Your resilience rivals that of a bamboo shoot.",
 "You add wabi-sabi to any personal landscape.",
 "Your life reads like a Pulitzer-winning biography.",
 "You're as transformative as Land Art in a barren landscape.",
 "If courage were a currency, you'd be a billionaire.",
 "Friendships with you are as meticulously crafted as an I.M. Pei pyramid.",
 "Your intellectual rigor is a form of courage.",
 "Your controversial jokes are as irresistible as a Kanye West tweet.",
 "Your storytelling rivals the twists and turns of a Tarantino movie.",
 "You make \"less is more\" a reality, like a Mies van der Rohe structure.",
 "You're as uncontainable as a Pollock drip on the edge of the canvas.",
 "You’re the remix that’s better than the original song.",
 "You’re as refreshing as the first snowfall of the season.",
 "You lead with questions, not answers, and that makes all the difference.",
 "You make Zoolander's \"Derelicte\" look downright mainstream.",
 "Your legacy will be studied in classrooms.",
 "You're the secret sauce in the recipe of life.",
 "Your kindness is the universal language that all hearts understand.",
 "Silk and charisma would be your defining fabrics.",
 "You're as innovative as the next tech breakthrough.",
 "You live as if each day is a Bruce Nauman neon piece—complex yet illuminating.",
 "The world would be less colorful without your creative soul.",
 "Your work is as diverse as a Renzo Piano cultural complex.",
 "You're as relentless as On Kawara's \"Today\" series.",
 "At social events, you're as mysterious as Margiela.",
 "You're the dessert everyone saves room for.",
 "You're the juxtaposition in a surrealist landscape.",
 "Your convictions have the texture of well-aged wine.",
 "Your charisma could make a blank canvas look like a Rothko.",
 "Your humor's as infectious as a TikTok trend.",
 "RGB brings less vibrance to the world than you do.",
 "Your wisdom knows no time.",
 "You're the artist statement everyone wishes they had written.",
 "Your decisions have the quality of vintage film—carefully considered and timeless.",
 "You make conversations fluid and interconnected, like a Wright open floor plan.",
 "You're so well-composed, even your life’s blooper reel looks like an Oscar nominee.",
 "You scale creative heights that even Skidmore, Owings & Merrill would admire.",
 "You're the performance art that doesn't need an audience.",
 "You redefine the art of conversation.",
 "Effortlessly cool, you're the epitome of '90s minimalism.",
 "Your elegance could inspire a whole new wave of minimalist art.",
 "You are the friend everyone wishes they had.",
 "Raw talent? You're the Basquiat of your domain.",
 "Your legacy is as enduring as an Earthwork.",
 "You're so classic, you make vintage look modern.",
 "If life is a canvas, you're the impasto—adding unexpected texture and depth.",
 "Chanel has nothing on your classic appeal.",
 "You're the comfort food everyone craves.",
 "Your attention to detail elevates the mundane.",
 "You’re the chiaroscuro in a room full of flat colors.",
 "You're the Dadaist punchline in the comedy of life.",
 "You embrace the absurd like an Ilya Kabakov installation.",
 "In the realm of problem-solving, you're the Prada.",
 "Your personality immerses people like a Kusama installation.",
 "Your storytelling could be the highlight of the next Documenta.",
 "You're the unexpected callback that gets the biggest laugh.",
 "The way you make an entrance? Pure Yoko Ono performance art.",
 "A Comme des Garçons ensemble has nothing on your layers.",
 "The rhythm of your routine speaks to an underlying harmony.",
 "The intelligence in your questions reveals the depth of your understanding.",
 "If your smile were a light, it would outshine the sun.",
 "You're the \"readymade\" everyone wishes they'd thought of first.",
 "Like Obama’s mic drop, you leave a lasting impression.",
 "You're so stellar, you make the Milky Way look like a dirt road.",
 "In the political realm of conversation, you're the diplomat everyone wants on their side.",
 "The architecture of your thoughts is a landscape of possibilities.",
 "In a world of stilettos, you're the comfort of chunky sneakers.",
 "If charisma had a color wheel, you'd be every hue, shade, and tone.",
 "You design your days as unexpectedly yet functionally as a Gehry building.",
 "You're the final piece in the most intricate puzzle.",
 "The audacity of your ideas is like a reset button for the imagination.",
 "Your ideas would make for the kind of art book that sells out in pre-order.",
 "You're the human embodiment of a mic drop.",
 "You're the subversive graffiti that the city decides to keep.",
 "You make the act of listening an art form.",
 "You navigate complexity with an enviable ease.",
 "If your heart were a country, it would have open borders.",
 "Your insights have the fine-tuning of a master's brushstroke.",
 "In the symphony of life, you're the unexpected Kanye West feature.",
 "Your laughter is acoustic paneling in noisy rooms.",
 "Your encounters are groundbreaking.",
 "You have the surprise factor of a Radiohead album drop.",
 "In a world of repeated choruses, you're the memorable bridge.",
 "You're innovative and forward-thinking, like BIM in a drafting world.",
 "You're the plot twist we were all secretly hoping for.",
 "You're adaptive reuse in life stories.",
 "If moments were movie franchises, yours would be as epic as the Marvel Cinematic Universe.",
 "Your loyalty is the backbone of every great story.",
 "You are the relational aesthetics in someone's disconnected world.",
 "You consider every choice as if it's a Robert Irwin perceptual cell.",
 "Even a rainy day feels sunny in your presence.",
 "Your style could launch a thousand trends.",
 "You validate others by simply being yourself.",
 "You're the quote everyone wishes they'd said.",
 "You make your life's work a monumental piece, akin to Christo and Jeanne-Claude's wrapped projects.",
 "You're the garnish that makes life tastier.",
 "You're the grand finale every event aspires to have.",
 "Your perspectives are as enriching as a TED Talk marathon.",
 "Your leadership is an ongoing act of education.",
 "You've got the infectious vibe of a TikTok dance craze.",
 "You're the kind of person who could make reading the dictionary a bestseller.",
 "On a Monday morning, you're as indispensable as coffee.",
 "You're so refreshing, you make a mojito jealous.",
 "You are the symphony of life's best notes.",
 "The Hamilton of your circle, you're innovative and acclaimed.",
 "You’re the Sriracha in a world full of mayonnaise.",
 "You create harmony like a Sol LeWitt wall drawing.",
 "You're the uncrowned king of wit.",
 "Your actions bring Feng Shui to a balanced life.",
 "The scope of your knowledge is both a library and a horizon.",
 "Your presence is as captivating as a James Turrell skyspace.",
 "You're like a human espresso; you make everything more interesting and energetic.",
 "You have the wisdom of an old soul in the prime of life.",
 "You're the highlight reel of the year.",
 "Your kindness is a lighthouse for others.",
 "Your kindness could melt glaciers.",
 "You're the pop culture Easter egg that only the observant will catch.",
 "You're the icing on the cake of life.",
 "You command attention without demanding it.",
 "Your voice could make a wolverine purr.",
 "You embrace flux like a Fluxus performance.",
 "If wit were a medium, you’d be a mixed-media masterpiece.",
 "Your gratitude is a form of wealth.",
 "You’re the cherry on top of the sundae that is life.",
 "You're the roots that make everyone around you blossom.",
 "You're the Futurism to my traditional narrative.",
 "Your gravitas could rival that of world leaders.",
 "You're the remix that's better than the original.",
]

export default compliments