import { styled } from '@stitches/react'
import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-modal'
import { BsBalloonFill, BsXLg } from "react-icons/bs";
import MIDISounds from 'midi-sounds-react'
Modal.setAppElement('body')

const SoundWrapper = styled('div', {
    opacity: 0,
    height: 0,
    pointerEvents: 'none'
})
const Music = ({play}) => {
    let midiSounds = useRef()
    let noteTimer = useRef(null)
    let playTimer = useRef(null)
    function toDuration(fractal) {
        return fractal * 0.1
    }

    let melody = [
        {
            chord: [60],
            duration: toDuration(2)
        },
        {
            chord: [60],
            duration: toDuration(1)
        },
        {
            chord: [62],
            duration: toDuration(3)
        },
        {
            chord: [60],
            duration: toDuration(3)
        },
        {
            chord: [65],
            duration: toDuration(3)
        },
        {
            chord: [64],
            duration: toDuration(5)
        },
        {
            chord: [],
            duration: toDuration(1)
        },
        {
            chord: [60],
            duration: toDuration(2)
        },
        {
            chord: [60],
            duration: toDuration(1)
        },
        {
            chord: [62],
            duration: toDuration(3)
        },
        {
            chord: [60],
            duration: toDuration(3)
        },
        {
            chord: [67],
            duration: toDuration(3)
        },
        {
            chord: [65],
            duration: toDuration(5)
        },
        {
            chord: [],
            duration: toDuration(1)
        },
        {
            chord: [60],
            duration: toDuration(2)
        },
        {
            chord: [60],
            duration: toDuration(1)
        },
        {
            chord: [72],
            duration: toDuration(3)
        },
        {
            chord: [69],
            duration: toDuration(3)
        },
        {
            chord: [65],
            duration: toDuration(3)
        },
        {
            chord: [64],
            duration: toDuration(3)
        },
        {
            chord: [62],
            duration: toDuration(3)
        },
        {
            chord: [70],
            duration: toDuration(2)
        },
        {
            chord: [70],
            duration: toDuration(1)
        },
        {
            chord: [69],
            duration: toDuration(3)
        },
        {
            chord: [65],
            duration: toDuration(3)
        },
        {
            chord: [67],
            duration: toDuration(3)
        },
        {
            chord: [65],
            duration: toDuration(5)
        },
    ];
    let instrument = 384
    function queueNote(melody, index) {
        if (index >= melody.length) {
            // Base case: if we've played all notes, stop recursion
            return;
        }

        let note = melody[index];
        let { chord, duration } = note

        midiSounds.current.playChordNow(instrument, chord, duration);

        noteTimer.current = setTimeout(() => {
            // Move on to the next note
            queueNote(melody, index + 1);
        }, duration * 1000 + 50);
    }
    useEffect(() => {
        if (play) {
            playTimer.current = setTimeout(() => {
                queueNote(melody, 0)
            }, 400)
        }
        return () => {
            clearTimeout(noteTimer.current);
            clearTimeout(playTimer.current);
        }
    }, [play]);

    return (
        <SoundWrapper>
            <MIDISounds 
                ref={midiSounds} 
                appElementName="root"
                instruments={[384]} 
            />	
        </SoundWrapper>
    );
}

const InfoButton = styled('button', {
    position: 'fixed',
    top: 0,
    right: 0,
    padding: 20,

    '&.close': {
        position: 'absolute'
    },
    '& svg': {
        width: 30,
        height: 30
    }
})
const Content = styled('div', {
    height: '100%',
    width: '100%',
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    background: 'white',
    // fontWeight: 'bold',
    fontSize: '18px',
    '& p ': {
        padding: 0,
        margin: 0,
        '+ p': {
            paddingTop: '1em',
        },
        textAlign: 'left'
    }
})
const Info = () => {
    // localStorage.clear();

    const read = localStorage.getItem('read') !== null
    const [ modalOpen, setModalOpen ] = useState(!read)


    function dismiss() {
        setModalOpen(false)
        localStorage.setItem('read', true)
    }
    return (<>
        {/* <Music play={modalOpen}/> */}
        <InfoButton onClick={() => setModalOpen(true)}><BsBalloonFill/></InfoButton>
        <Modal
            isOpen={modalOpen}
            onRequestClose={dismiss}
            style={{overlay: {},content: {}}}
            contentLabel={'Info'}
            className={`DefaultModal`}
            overlayClassName={`DefaultOverlay`}
            portalClassName={`ReactModalPortal`}
            bodyOpenClassName={`modalOpen`}
            closeTimeoutMS={400}
        >
            <InfoButton className="close" onClick={dismiss}>
                <BsXLg/>
            </InfoButton>
            <Content>
                <p>
                    Dear Roos,
                </p><p>
                    Here are 365 unique compliments just for you! Something to get yourself hyped up for every day this year.
                </p>
                <p>
                    Courtesy of SB® & GPT 4.
                </p>
                <p>
                    Happy Birthday,<br/>
                    Carla & Karlis
                </p>
            </Content>
        </Modal>
    </>)
}
export default Info